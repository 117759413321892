<template>
  <b-container fluid="xl">
    <div v-if="!submissionComplete">
      <h3>{{ $t("CommunityOfPractice.Header") }}</h3>

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider
            v-slot="{ errors, valid }"
            name="teacher-vp"
            :rules="{ required: true }"
          >
            <b-form-group
              id="teacher-group"
              :label="$t('CommunityOfPractice.Questions.TeacherLeader')"
              label-for="teacher"
              class="required-field"
            >
              <b-form-select
                id="teacher"
                v-model="model.TeacherLeader"
                :options="teacherOptions"
                :state="errors[0] ? false : valid ? true : null"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <h2 class="h5 required-field">
            {{ $t("CommunityOfPractice.Questions.Role") }}
          </h2>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Role"
            :rules="{ required: true }"
          >
            <b-form-group>
              <b-form-checkbox-group
                id="role"
                v-model="model.Roles"
                :options="roleOptions"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <template v-if="NotVolunteer">
            <template v-if="model.Roles.includes('Other')">
              <validation-provider
                v-slot="{ errors, valid }"
                name="OtherRoles"
                rules="required"
              >
                <b-form-group
                  id="other-roles-group"
                  label="Please Specify"
                  label-for="other-roles"
                >
                  <b-form-input
                    id="other-roles"
                    class="binput"
                    type="text"
                    v-model="model.OtherRoles"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <validation-provider
              v-slot="{ errors, valid }"
              name="country"
              :rules="{ required: true }"
            >
              <b-form-group
                id="country-group"
                label="Country"
                label-for="country"
                class="required-field"
              >
                <b-form-select
                  id="country"
                  class="binput"
                  v-model="model.Country"
                  :options="countryOptions"
                  :state="errors[0] ? false : valid ? true : null"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="valid">
                <span v-for="(error, index) in errors" :key="index">{{
                  $t(error)
                }}</span>
              </b-form-invalid-feedback>
            </validation-provider>

            <template v-if="model.Country === 'Canada'">
              <hr />
              <your-school v-model="model.School" />
              <hr />

              <h2 class="h5">
                {{ $t("CommunityOfPractice.ClassCollectionLabel") }}
              </h2>
              <validation-provider
                v-slot="{ errors, valid }"
                name="classcollection"
                :rules="{ required: true, min: 1 }"
              >
                <grade-collection
                  ref="gradeCollection"
                  id="gradecollection"
                  description-values
                  v-model="model.Grades"
                />
                <b-form-invalid-feedback :state="valid">
                  <span v-for="(error, index) in errors" :key="index">{{
                    $t(error)
                  }}</span>
                </b-form-invalid-feedback>
              </validation-provider>
            </template>
          </template>

          <div style="margin-top: 10px">
            <b-button
              id="btn_submit"
              type="submit"
              variant="primary"
              class="mt-2"
              :disabled="saving"
              >{{ $t("Submit") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <b-container id="confirmationheader">
        <h3>{{ $t("CommunityOfPractice.Confirmation.Header") }}</h3>
      </b-container>
      <hr />
      <p
        id="confirmationbody"
        v-html="$t('CommunityOfPractice.Confirmation.Body')"
      />
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { min, numeric, min_value } from "vee-validate/dist/rules";
import GradeCollection from "@/components/GradeCollection.vue";
import ConfigurationService from "@/services/ConfigurationService";
import OrganizationApiService from "@/services/OrganizationApiService";
import OrgRegionSelect from "@/components/school/OrgRegionSelect.vue";
import FormBase from "@/types/FormBase";
import YourSchool from "@/components/questions/YourSchool.vue";

extend("min", {
  ...min,
  message: "Validation.MinLength",
});

extend("min_value", {
  ...min_value,
  message: "Validation.ProvideNumericGreaterThanZero",
});

extend("numeric", {
  ...numeric,
  message: "Validation.Numeric",
});

// example config
// {"teachers":["Guy Dudeson","Girl Girlson"]}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    GradeCollection,
    OrgRegionSelect,
    YourSchool,
  },
})
export default class CommunityOfPractice extends FormBase {
  name = "";
  model: any = {};

  orgApiService: OrganizationApiService = new OrganizationApiService();
  returnUrl: string | null = null;

  configService: ConfigurationService = ConfigurationService.getInstance();

  get Form(): string {
    return "CommunityOfPractice";
  }

  get Instance(): string | null {
    return this.$route.params.instance;
  }

  get Locale(): string {
    return this.$i18n.locale;
  }

  get IdServerUrl(): string {
    return this.configService.getUrl("ltsol_id");
  }

  get roleOptions() {
    return [
      {
        text: this.$root.$t("CommunityOfPractice.Roles.Volunteer"),
        value: "Volunteer",
      },
      {
        text: this.$root.$t("CommunityOfPractice.Roles.Educator"),
        value: "Educator",
      },
      {
        text: this.$root.$t("CommunityOfPractice.Roles.Other"),
        value: "Other",
      },
    ];
  }

  get countryOptions() {
    return [
      { text: "Canada", value: "Canada" },
      { text: "United States", value: "United States" },
      {
        text: this.$root.$t("CommunityOfPractice.Countries.Other"),
        value: "Other",
      },
    ];
  }

  get teacherOptions() {
    if (!this.Metadata || !this.Metadata.teachers) {
      return [];
    } else {
      return this.Metadata.teachers.map((t: any) => {
        return {
          text: t,
          value: t,
        };
      });
    }
  }

  get NotVolunteer(): boolean {
    return (
      this.model &&
      this.model.Roles &&
      this.model.Roles.findIndex((x: string) => x !== "Volunteer") >= 0
    );
  }

  mounted() {
    if (this.$route.query.returnurl) {
      this.returnUrl = this.$route.query.returnurl.toString();
    }

    this.GetFormData();
  }

  onSubmit() {
    let gradeCollectionValid = false;
    if (this.$refs.gradeCollection !== undefined) {
      gradeCollectionValid = (
        this.$refs.gradeCollection as GradeCollection
      ).validate();
    } else {
      gradeCollectionValid = true;
    }

    if (gradeCollectionValid === true) {
      this.SaveSubmission(this.model);
    }
  }
}
</script>
<style scoped>
.headerimg {
  max-width: 500px;
}

.question {
  padding-top: 20px;
}

#form {
  padding: 10px;
}

#submitbtn {
  margin-top: 25px;
}

.binput {
  max-width: 250px;
}
textarea.binput {
  max-width: 100%;
}

.step-progress {
  display: inline;
}

hr {
  background-color: rgb(145, 145, 145);
}

.phelp {
  font-style: italic;
}

#noschool-details:invalid {
  border: 1px solid red;
}

#noschool-details:valid {
  border: 1px solid green;
}

#confirmationheader {
  margin-top: 30px;
  text-align: center;
}

#confirmationsub {
  margin-top: 30px;
}

#confirmationbody {
  margin-top: 30px;
}

#confirmationbtncontainer {
  margin-top: 30px;
}

#headercontainer {
  text-align: center;
}

.question {
  font-weight: bold;
}
</style>
